import React from 'react';

interface props{

    name: string;

}

export const CommonRoomUser = (Props: props) =>{
    

  return(
    <div className = 'commonRoomUser three'>
        <img className = 'profile '  src = {require('../../../assets/images/tempImage.jpg')}/>
        <p className = 'name semi-bold primary'>{Props.name}</p>
        <div className = 'activity'/>
    </div>
  )
}
