import React from 'react';
import { Button } from '@material-ui/core';

interface props{
    label: string;
    type: any;
    onClick: any;
}

export const SecondaryButton = (Props: props) =>{


  return(
    <div className = 'secondaryButtonContainer'>
        <Button onClick = {Props.onClick} type = {Props.type} className = 'white secondaryBtn'>{Props.label}</Button>
    </div>
  )
}
