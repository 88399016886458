import React from 'react';

interface props{
    label: string;
}

export const DefaultBtn = (Props: props) =>{


  return(
    <div className = 'defaultBtnContainer'>
        <button className = 'white defaultBtn'>{Props.label}</button>
    </div>
  )
}
