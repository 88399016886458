import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const Attorneys = () =>{
  return (
    <div>
      <div className = 'top holder'>
        <h1 className = 'primary line'>Our Attorneys</h1>
        <div className = 'one grg20'>
            <div className = 'inputGroup'><span className="innericon searchIconBlue"/><input placeholder = 'Search' className = 'defaultSearch' type='text'/></div>
        
            <div className = 'attorneys three'>
                <img className = 'attorneyLogo' src = {require('../../../assets/images/adamsandadams.png')}/>
                <img className = 'attorneyLogo' src = {require('../../../assets/images/curran.png')}/>
                <img className = 'attorneyLogo' src = {require('../../../assets/images/gcmlegal.png')}/>

                <img className = 'attorneyLogo' src = {require('../../../assets/images/kmg.png')}/>
                <img className = 'attorneyLogo' src = {require('../../../assets/images/rubensteins.png')}/>
                <img className = 'attorneyLogo' src = {require('../../../assets/images/straussdaly.png')}/>

                <img className = 'attorneyLogo' src = {require('../../../assets/images/molaba.png')}/>
            </div>
      </div>
      </div>
    </div>
  )
}

export default Attorneys