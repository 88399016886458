import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const Lectures = () =>{

  return (
    <div>
      <div className = 'holder top'>
        <div className = 'lectures one'>
            <div className = 'top flexRow'>
                <p className = 'light primary'>*All lectures are held on Saturdays from 09:00pm - 10:00m </p>
                <BasicComponents.DefaultBtn label = 'request a topic'/>
            </div>
            <div className = 'break'/>
            <div className = 'two currentLectures'>
                <div className = 'left'>
                    <h1 className = 'primary'>This Weeks Lecture</h1>
                    <div className = 'card two'>
                        <div className = 'cardTop'>
                            <h2 className = 'line primary bold'>Tax Law</h2>
                        </div>
                        <div className = 'cardLeft'>
                            <img className = 'profilePicture' src = {require('../../../assets/images/tempImage.jpg')}/>
                        </div>
                        <p className = 'primary'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat officia nam voluptates vitae. Sint possimus soluta debitis labore, voluptates ut?
                            <br/>
                            <br/>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam eum voluptatibus voluptates eos nesciunt quidem in quo dolor quia officia.
                        </p>
                        
                        <div className = 'cardBottom'>
                            <h4 className = 'primary medium noMarginBottom'>Speaker: </h4>
                            <h3 className = 'primary noMarginBottom'>James Webb</h3>
                        </div>
                    </div>
                </div>
                <div className = 'verticalBreak'/>
                <div className = 'right'>
                    <h1 className = 'primary'>Next Weeks Lecture</h1>
                    <div className = 'card two'>
                        <div className = 'cardTop'>
                            <h2 className = 'line primary bold'>Tax Law</h2>
                        </div>
                        <div className = 'cardLeft'>
                            <img className = 'profilePicture' src = {require('../../../assets/images/tempImage.jpg')}/>
                        </div>
                        <p className = 'primary'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat officia nam voluptates vitae. Sint possimus soluta debitis labore, voluptates ut?
                            <br/>
                            <br/>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam eum voluptatibus voluptates eos nesciunt quidem in quo dolor quia officia.
                        </p>
                        
                        <div className = 'cardBottom'>
                            <h4 className = 'primary medium noMarginBottom'>Speaker: </h4>
                            <h3 className = 'primary noMarginBottom'>James Webb</h3>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className = 'primary noMargin'>Upcomming Lectures</h1>
            <div className = 'break'/>
        </div>
      </div>
    </div>
  )
}

export default Lectures