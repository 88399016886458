import React, { useState } from 'react';
import BasicComponents from '../../basicComponents/index'
import * as Yup from 'yup'
import { Formik, Form, FormikProps } from 'formik'

import { Link } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { useAuthenticationProvider } from '../../../context/authenticationProvider';

interface UserObject {
  first_name: string
  last_name: string
  email: string
  password: string
  confirm_password: string
}

export const SignUpStepOne = () =>{

  const [errorMessage, setErrorMessage] = useState('An Error has occured.');
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { auth, authSet } = useAuthenticationProvider()

  const createNewUser = async (data: UserObject, resetForm: Function) => {
  };


  return(
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        mobile:'',
        password: '',
        confirm_password: ''
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], 'Passwords must match')
            .required('Confirm Password is required')
    })}

    onSubmit={fields => {
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4))
  }}

    >
      {(formik: any) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty
        } = formik;
        return(
            <div className = 'signUpModal'>
              <h2 className = 'primary light tcenter'>Welcome to Virtual Chambers</h2>
                  <div className = 'three signUpContent gcg30'>

                      <div className = 'signIn one'>
                          <h4 className = 'primary light'>Sign Up</h4>
                          <input 
                            placeholder = 'Enter your email address'
                            className = {errors.email && touched.email ? 
                              "defaultInput" : 'defaultInput'}
                          />
                          <input className = 'defaultInput' placeholder = 'Enter your email address'></input>
                          <BasicComponents.SecondaryButton onClick = {()=> {authSet(!auth)}} type='' label ='Sign In'/>
                      </div>
                      <div className = 'verticalBreak'/>
                      <Form>
                        <div className = 'signUp one'>
                            <h4 className = 'primary light'>Join</h4>
                            <input 
                              placeholder = 'Name'
                              name = 'first_name'
                              type = 'text'
                              id= 'first_name'
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            <input 
                              placeholder = 'Surname'
                              name = 'last_name'
                              type = 'text'
                              id= 'last_name'
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            <input 
                              placeholder = 'Email Address'
                              name = 'email'
                              type = 'text'
                              id= 'email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            <input 
                              placeholder = 'Mobile Number'
                              name = 'mobile'
                              type = 'text'
                              id= 'mobile'
                              value={values.mobile}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            <input 
                              placeholder = 'Password'
                              name = 'password'
                              type = 'text'
                              id= 'password'
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            <input 
                              placeholder = 'Confirm Password'
                              name = 'confirm_password'
                              type = 'text'
                              id= 'confirm_password'
                              value={values.confirm_password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className = 'defaultInput'
                            />
                            <BasicComponents.SecondaryButton
                              label = 'Join'
                              type="submit"
                              onClick = {()=> {authSet(!auth)}}
                            />
                        </div>
                      </Form>
                  </div>
            </div>
          );
        }}
    </Formik>
  )
}