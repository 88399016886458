import React from 'react';

import { Link } from 'react-router-dom';

import BasicComponents from '../../basicComponents/index'

export const CommonRoomsCard = () =>{
  return(

    <div className = 'commonRoomCard card one'>
        <div className = 'top two'>
          <div className = 'left'>
            <h4 className = 'primary line roomHeading'>Morning Coffee</h4>
            <p className = 'roomDescription primary light'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis repellendus fugiat illo, laborum dicta eligendi?</p>
          </div>
          <img className = 'roomIcon' src = {require('../../../assets/images/coffeeRoom.png')}/>
        </div>
        <div className = 'flexRow spaceBetween'>
          <p className = 'primary medium'>Whos In?</p>
          <p className = 'primary medium'>Join</p>
        </div>
        <div className = 'users two'>
          <BasicComponents.CommonRoomUser name = 'Alex Phillips'/>
          <BasicComponents.CommonRoomUser name = 'Alex Phillips'/>
          <BasicComponents.CommonRoomUser name = 'Alex Phillips'/>
          <BasicComponents.CommonRoomUser name = 'Alex Phillips'/>
        </div>
    </div>
  )
}