import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const ProfilePage = () =>{

  return (
    <div>
      <div className = 'holder top'>
        <div className = 'profilePage one grg30'>
            <div className = 'top four gcg20'>
                <div className = 'personalInformation gcg20 two'>
                    <img className = 'profileImage' src = {require('../../../assets/images/tempImage.jpg')}/>

                    <div className = 'personalInformationContent'>
                        <h1 className = 'primary bold noMarginBottom'>John Doe</h1>
                        <h2 className = 'primary medium noMargin'>Attorney</h2>
                        <div className = 'flexRow'>
                            <img className = 'icon' src = {require('../../../assets/images/envelopeBlue.png')}/>
                            <p className = 'primary light'>email@example.com</p>
                        </div>
                        <div className = 'flexRow'>
                            <img className = 'icon' src = {require('../../../assets/images/phoneBlue.png')}/>
                            <p className = 'primary light'>+27 84 334 2345</p>
                        </div>
                    </div>
                </div>
                <div className = 'verticalBreak'/>
                <div className = ' geographic one grg10'>
                    <div className = 'iconHeading flexRow'>
                        <img className = 'icon' src = {require('../../../assets/images/locationBlue.png')}/>
                        <h1 className = 'primary noMargin'>Geographic <br/> Location</h1>
                    </div>
                    <div className = 'break'/>
                    <p className = 'primary light'>Johannesburg</p>
                </div>
                
                <div className = 'verticalBreak'/>

                <div className = 'advocateDetails one grg10'>
                    <div className = 'iconHeading flexRow'>
                        <img className = 'icon' src = {require('../../../assets/images/calanderBlue.png')}/>
                        <h4 className = 'primary noMargin'>Date of Admission as Advocate</h4>
                    </div>
                    <div className = 'break'/>
                    <p className = 'primary light'>11 August 1997</p>

                    <div className = 'iconHeading flexRow'>
                        <img className = 'icon' src = {require('../../../assets/images/attorneysRoom.png')}/>
                        <h4 className = 'primary noMargin'>Legal Practice Counsel Number</h4>
                    </div>
                    <div className = 'break'/>
                    <p className = 'primary light'>11 August 1997</p>
                </div>

                <div className = 'verticalBreak'/>

                <div className = 'skillSet one grg10'>
                    <div className = 'iconHeading flexRow'>
                        <img className = 'icon' src = {require('../../../assets/images/calanderBlue.png')}/>
                        <h1 className = 'primary noMargin'>Skill Set</h1>
                    </div>
                    <div className = 'break'/>
                    <ul className = 'noMargin skillList'>
                        <li className = 'primary light noMargin'>This is skill one</li>
                        <li className = 'primary light noMargin'>This is skill 2</li>
                        <li className = 'primary light noMargin'>This is skill 3</li>
                    </ul>
                </div>

            </div>
            <div className = 'break'/>
            <div className = 'bottom two'>
                <div className = 'left'>
                    <BasicComponents.DefaultBtn label = 'Request a meeting'/>
                </div>
                <div className = 'right one'>
                    <div className = 'iconHeading flexRow'>
                        <img className = 'icon' src = {require('../../../assets/images/buisnessCard.png')}/>
                        <h1 className = 'primary noMargin'>Bio</h1>
                    </div>
                    <p className = 'primary light'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vel sem nec placerat. In at rutrum velit. Phasellus placerat turpis et magna semper, vel congue elit lobortis. Fusce suscipit massa nisi, sit amet pretium orci posuere at. Cras sed pretium ipsum. Phasellus sollicitudin erat Leo, porttitor elementum nulla commodo sit amet. Vivamus porttitor facilisis vestibulum. Nunc tempor cursus aliquet. Duis dapibus tellus justo, in lacinia ex feugiat quis.

In sollicitudin, eros ac dignissim condimentum, justo justo tempus risus, vel convallis nisi felis a felis. Quisque ornare, orci eget lobortis dignissim, purus tortor luctus sapien, a malesuada nibh risus volutpat.
                    </p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage