import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const WhyJoin = () =>{
  return (
    <div>
      <div className = 'holder top'>
          <h1 className = 'primary line'>Why Join?</h1>
          <div className = 'whyJoinGrid one'>
              <div className = 'content two'>
                <div className = 'contentLeft'>
                    <h1 className = 'contentHeading primary medium'>Be Part of an Illustrious Group of Attorneys</h1>
                    <p className = 'content primary light'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ducimus cupiditate animi tenetur quasi soluta placeat doloribus nostrum ipsam, totam obcaecati necessitatibus, quidem aliquam dolore? Soluta quaerat alias voluptas sint. Iusto magnam cumque aliquid tempore autem, ea in soluta suscipit hic perspiciatis architecto ex officia laudantium expedita dolore mollitia quas odio reiciendis quasi dolor nemo. Mollitia voluptate non iure dolores odio aliquid, quasi officiis accusamus impedit eos consequuntur eveniet porro harum culpa illo ad sit eaque ipsam magni! Nulla asperiores nostrum, accusamus ex id at excepturi minima inventore, quos laudantium aliquid iste. Blanditiis optio earum alias molestiae sed. Ipsum, aperiam?
                    </p>
                    <div className = 'button'>
                        <BasicComponents.DefaultBtn label = 'join virtual chambers'/>
                    </div>
                </div>
                <div className = 'contentRight'>
                    <img className = 'contentImage' src = {require('../../../assets/images/membersImage.png')}/>
                </div> 
              </div>
              <div className = 'content two'>
                <div className = 'contentLeft'>
                    <h1 className = 'contentHeading primary medium'>Be Part of an Illustrious Group of Attorneys</h1>
                    <p className = 'content primary light'>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi amet illum atque corporis facilis ratione fugit natus, laborum consectetur itaque eos at debitis aut. Autem facilis quibusdam alias quis neque maxime inventore dolores, suscipit facere quas quae molestiae ullam, enim quidem vitae veniam laboriosam totam asperiores doloribus provident cumque quaerat?
                    </p>
                    <div className = 'button'>
                        <BasicComponents.DefaultBtn label = 'join virtual chambers'/>
                    </div>
                </div>
                <div className = 'contentRight'>
                    <img className = 'contentImage' src = {require('../../../assets/images/commonroomsImage.png')}/>
                </div> 
              </div>
          </div>
      </div>
    </div>
  )
}

export default WhyJoin