import { WebsiteNav } from './websiteNav/websiteNav';
import { WebsiteFooter } from './websiteFooter/websiteFooter';
import { NoticeBoard } from './noticeBoard/noticeBoard';
import { DashboardCommonRoom } from './dashboardCommonRoom/dashboardCommonRoom';
import { LecturesTable } from './lecturesTable/lecturesTable';
import { DashboardLecturesTable } from './dashboardLecturesTable/dashboardLecturesTable';
import { IndustryNews } from './industryNews/industryNews';
import { CommonRoomsCard } from './commonRoomCard/commonRoomCard';
import { MembersCard } from './membersCard/membersCard';

import { SignUpStepOne } from './login/signUpStepOne';
import { SignUpStepTwo } from './login/signUpStepTwo';

export default {
  WebsiteNav,
  WebsiteFooter,
  NoticeBoard,
  DashboardCommonRoom,
  LecturesTable,
  DashboardLecturesTable,
  IndustryNews,
  CommonRoomsCard,
  MembersCard,
  SignUpStepOne,
  SignUpStepTwo,
};
