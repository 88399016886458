import React from 'react';

import { Link } from 'react-router-dom';

export const NoticeBoard = () =>{
  return(

    <div className = 'noticeBoard dashboardContainer'>
        <div className = 'one'>

            <div className = 'top'>
                <h1 className = 'primary line'>Notice Board</h1>
                <div className = 'right'>
                    <p className = 'dateYear primary'>2020 <br/> <span className = 'dateMonth'>August</span><span className = 'dateDay'>18</span></p>
                </div>
            </div>

            <div className = 'board two'>

                    <div>
                        <p className = 'primary medium'>Member Notification</p>
                        <div className = 'boardItem'>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                        </div>
                    </div>

                    <div>
                        <p className = 'primary light'>Coming this week...</p>
                        <div className = 'boardItem'>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                        </div>
                    </div>

                    <div>
                        <p className = 'primary medium'>Member Notification</p>
                        <div className = 'boardItem'>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                        </div>
                    </div>

                    <div>
                        <div className = 'boardItem'>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                            <p className = 'primary'>Member Meeting <span className = 'bold'>| 09:30am - 10:00am</span></p>
                        </div>
                    </div>
            </div>
        </div>
    </div>
  )
}