import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import { DefaultBtn } from '../../../components/basicComponents/defaultBtn/defaultBtn';

const ContactUs = () =>{
  return (
    <div>
        <div className = 'top holder contactUs'>
            <h1 className = 'line primary'>Contact Us</h1>
            <div className = 'one'>
                <div className = 'top two'>
                    <div className = 'left'>

                        <div className = 'flexRow'>
                            <img src = {require('../../../assets/images/phoneBlue.png')}/>
                            <p className = 'primary medium'>Call us on: </p>
                            <p className = 'primary bold'>+27 78 464 7352</p>
                        </div>

                        <div className = 'flexRow'>
                            <img src = {require('../../../assets/images/envelopeBlue.png')}/>
                            <p className = 'primary medium'>Call us on: </p>
                            <p className = 'primary bold'>+27 78 464 7352</p>
                        </div>

                    </div>
                </div>
                <h2 className = 'primary'>Or Contact Us Here</h2>
                <input className = 'defaultInput' placeholder = 'Enter your email address'></input>
                <input className = 'defaultInput' placeholder = 'Enter your name and surname'></input>
                <textarea className = 'defaultTextarea' placeholder = 'Type your message'/>
                <div className = 'flexEnd'>
                    <DefaultBtn label = 'send email'/>
                </div>
                <h2 className = 'primary'>Want us to contact you?</h2>
                <input className = 'defaultInput' placeholder = 'Enter your email address'></input>
            </div>
        </div>  
    </div>
  )
}

export default ContactUs