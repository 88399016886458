import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const Homepage = () =>{

  return (
    <div>
      <div className = 'heroImage'>
        <div className = 'holder'>
          <div className = 'introGrid'>
            <div className = 'top center tcenter'>
              <img className = 'whiteLogo' src = {require('../../../assets/images/logoWhite.png')}/>
              <h2 className = 'white'>JOIN THE CHAMBER OF FORWARD-THINKING ADVOCATES</h2>
            </div>
            <div className = 'four'>
              <div className = 'introCard right'>
                <img className = 'introIcon' src = {require('../../../assets/images/edit.png')}/>
                <h3 className = 'white introHeading'>Fully ltc complient</h3>
                <p className = 'white introText'>Geographically unlimited. You choose your space and your place.</p>
              </div>
              <div className = 'introCard right'>
                <img className = 'introIcon' src = {require('../../../assets/images/noCoins.png')}/>
                <h3 className = 'white introHeading'>No shared costs</h3>
                <p className = 'white introText'>Geographically unlimited. You choose your space and your place.</p>
              </div>
              <div className = 'introCard right'>
                <img className = 'introIcon' src = {require('../../../assets/images/globe.png')}/>
                <h3 className = 'white introHeading'>Your chambers</h3>
                <p className = 'white introText'>Geographically unlimited. You choose your space and your place.</p>
              </div>
              <div className = 'introCard'>
                <img className = 'introIcon' src = {require('../../../assets/images/headset.png')}/>
                <h3 className = 'white introHeading'>Members forum</h3>
                <p className = 'white introText'>Geographically unlimited. You choose your space and your place.</p>
              </div>
            </div>
            <BasicComponents.DefaultBtn label = 'join virtual chambers'/>
          </div>
        </div>
      </div>
      <div className = 'commonRoomSection'>
        <div className = 'holder'>
          <div className = 'one'>
            <div className = 'top'>
              <h1 className = 'white'>Join The Common Rooms</h1>
              <h3 className = 'white'>Currently...</h3>
            </div>
            <div className = 'four center'>
              <div className = 'commonRoomTile center right'>
                <div className = 'flexRow'><h1 className = 'contentUnit white'>4</h1><img className = 'commonRoomIcon' src = {require('../../../assets/images/messageIcon.png')}/></div>
                <p className = 'white contentText'>Chat rooms are in use.</p>
              </div>
              <div className = 'commonRoomTile center right'>
                <div className = 'flexRow'><h1 className = 'contentUnit white'>16</h1><img className = 'commonRoomIcon' src = {require('../../../assets/images/userGroup.png')}/></div>
                <p className = 'white contentText'>Advocates are in discussion.</p>
              </div>
              <div className = 'commonRoomTile center right'>
                <div className = 'flexRow'><h1 className = 'contentUnit white'>56</h1><img className = 'commonRoomIcon' src = {require('../../../assets/images/scaleIcon.png')}/></div>
                <p className = 'white contentText'>Cases are being discussed.</p>
              </div>
              <div className = 'commonRoomTile center'>
                <h3 className = 'white contentText'>Upcomming Room Topics</h3>
                <p className = 'white contentText'>Morning Coffee | <span className = 'contentDetail'>8:30 am</span></p>
                <p className = 'white contentText'>Case Law | <span className = 'contentDetail'>8:30 am</span></p>
                <p className = 'white contentText'>Property casses | <span className = 'contentDetail'>8:30 am</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className = 'lectures'>
        <div className = 'backgroundImage'>
          <div className = 'holder'>
            <div className = 'lecturesTable one center'>
              <h1 className = 'primary tcenter'>About Our Lectures</h1>
              <p className = 'primary tcenter lectureContent'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione, cupiditate ullam dolor nulla, eaque nisi corrupti voluptatibus molestiae voluptas quos numquam reiciendis dolores recusandae dolorem.</p>
              <h1 className = 'primary'>This Weeks Topics</h1>
              <div className = 'homepageTableHolder'>
                <EnhancedComponents.LecturesTable/>
              </div>
              <BasicComponents.DefaultBtn label = 'join virtual chambers'/>
            </div>
          </div>
        </div>
      </div>

      <div className = 'blogs'>
        <div className = 'holder'>
          <h1 className = 'secondary'>Case Blogs</h1>
          <div className = 'two'>
            <h3 className = 'secondary underline'>Law Case Change</h3>
            <h3 className = 'secondary underline'>Industry News</h3>
            <div className = 'blogLeft'>
              <div className = 'blogTop two'>
                <img className = 'blogThumbnailLarge' src = {require('../../../assets/images/lecturesBg.jpg')}/>
                <div className = 'topBlogContent'>
                  <h4 className = 'secondary headingOne uppercase'>Heading 1</h4>
                  <h5 className = 'secondary headingTwo uppercase'>Heading 2</h5>
                  <p className = 'secondary blogContent'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat facere magnam possimus nulla exercitationem autem labore?</p>
                </div>
              </div>
              <div className = 'blogBottom two'>
                <div className = 'bottomLeft'>
                  <img className = 'blogThumbnailSmall' src = {require('../../../assets/images/lecturesBg.jpg')}/>
                  <h4 className = 'bottomBlogHeading uppercase secondary'> Heading</h4>
                  <p className = 'secondary blogContent'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis laudantium consequatur quidem sapiente!</p>
                </div>
                <div className = 'bottomRight'>
                  <img className = 'blogThumbnailSmall' src = {require('../../../assets/images/lecturesBg.jpg')}/>
                  <h4 className = 'bottomBlogHeading uppercase secondary'> Heading</h4>
                  <p className = 'secondary blogContent'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat facere magnam possimus nulla</p>
                </div>
              </div>
            </div>
            <div className = 'blogRight'>
              <div className = 'blogTop two'>
                <img className = 'blogThumbnailLarge' src = {require('../../../assets/images/lecturesBg.jpg')}/>
                <div className = 'topBlogContent'>
                  <h4 className = 'secondary headingOne uppercase'>Heading 1</h4>
                  <h5 className = 'secondary headingTwo uppercase'>Heading 2</h5>
                  <p className = 'secondary blogContent'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat facere magnam possimus nulla exercitationem autem labore?</p>
                </div>
              </div>
              <div className = 'blogBottom two'>
                <div className = 'bottomLeft'>
                  <img className = 'blogThumbnailSmall' src = {require('../../../assets/images/lecturesBg.jpg')}/>
                  <h4 className = 'bottomBlogHeading uppercase secondary'> Heading</h4>
                  <p className = 'secondary blogContent'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis laudantium consequatur quidem sapiente!</p>
                </div>
                <div className = 'bottomRight'>
                  <img className = 'blogThumbnailSmall' src = {require('../../../assets/images/lecturesBg.jpg')}/>
                  <h4 className = 'bottomBlogHeading uppercase secondary'> Heading</h4>
                  <p className = 'secondary blogContent'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat facere magnam possimus nulla</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homepage