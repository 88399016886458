import React from 'react';

import { Link } from 'react-router-dom';

export const LecturesTable = () =>{
  return(

    <div className = 'topicTable two'>
        <div className = 'topicTableHeading'><h1 className = 'white'>Topics</h1></div>
        <div className = 'topicTableHeading'><h1 className = 'white'>Speaker</h1></div>
        <div className = 'topicContent right'><p className = 'primary'>Tax Law</p></div>
        <div className = 'topicContent'><p className = 'primary'>James Webb</p></div>
        <div className = 'topicContent right'><p className = 'primary'>Property Law</p></div>
        <div className = 'topicContent'><p className = 'primary'>John Smith</p></div>
        <div className = 'topicContent right'><p className = 'primary'>The Legal System</p></div>
        <div className = 'topicContent'><p className = 'primary'>Claire Jones</p></div>
    </div>
  )
}