import * as React from "react";
type AuthenticationType = {
  auth: boolean;
  authSet: (auth: boolean) => void;
};
export const AuthenticationContext = React.createContext<AuthenticationType>({
    auth: false,
    authSet: () => null
});
export const useAuthenticationProvider = () => React.useContext(AuthenticationContext);
const AuthenticationProvider: React.FC<{}> = ({ children }) => {
  const [auth, setAuth] = React.useState<boolean>(false);
  function authSet(auth: boolean) {
    setAuth(auth);
  }
  return (
    <AuthenticationContext.Provider
      value={{
        auth,
        authSet
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
export default AuthenticationProvider;