import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const SkillsVault = () =>{

  return (
    <div>
      <div className = 'holder top'>
            <h1 className = 'primary line'>Our Skills Vault</h1>
            <div className = 'skillsVault one'> 
                <div className = 'inputGroup'><span className="innericon searchIconBlue"/><input placeholder = 'Search' className = 'defaultSearch' type='text'/></div>
                <div className = 'three skills list gcg30'>
                    <div>
                      <BasicComponents.SkillsListDropdown label = 'Admirality'/>
                      <BasicComponents.SkillsListDropdown label = 'Animal'/>
                      <BasicComponents.SkillsListDropdown label = 'Adoptions'/>
                    </div>
                    
                    <div>
                      <BasicComponents.SkillsListDropdown label = 'Banking'/>
                      <BasicComponents.SkillsListDropdown label = 'Buisness Rescue'/>
                    </div>
                    <div>
                      <BasicComponents.SkillsListDropdown label = 'Civil Rights'/>
                      <BasicComponents.SkillsListDropdown label = 'Communication'/>
                      <BasicComponents.SkillsListDropdown label = 'Constitution'/>
                    </div>
                </div>
            </div>
      </div>
    </div>
  )
}

export default SkillsVault