import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

import { DefaultBtn } from '../../../components/basicComponents/defaultBtn/defaultBtn';

const CommonRooms = () =>{
  return (
      <div className = 'commonRooms two'>
        <div className = 'commonRoomsLeft one'>
          <h1 className = 'primary'>Common Rooms</h1>
          <div className = 'break'/>
          <h4 className = 'primary'>Permanent Rooms</h4>
          <div className = 'roomsGrid three'>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>

            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
          </div>
          <div className = 'break'/>
          <h4 className = 'primary'>Requested Rooms</h4>
          <div className = 'roomsGrid three'>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>

            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
            <EnhancedComponents.CommonRoomsCard/>
          </div>
          <BasicComponents.DefaultBtn label = 'Request a Room'/>
        </div>
        <div className = 'commonRoomsRight one'>
          <h1 className = 'white'> Members Online</h1>
        </div>
      </div>
  )
}

export default CommonRooms