import React from 'react';

import { Link } from 'react-router-dom';

export const WebsiteFooter = () =>{
  return(
    <div className = 'footer'>
        <div className = 'holder'>
            <div className = 'one footerGrid'>
                <div className = 'footerTop two'>
                    <div className = 'footerLinks'>
                        <h3 className = 'footerHeader white uppercase'>Navigation</h3>
                        <ul className = 'linkList'>
                            <li className = 'linkItem white uppercase'>Home</li>
                            <li className = 'linkItem white uppercase'>Contacts</li>
                            <li className = 'linkItem white uppercase'>More Information</li>
                            <li className = 'linkItem white uppercase'>Privacy Policy</li>
                            <li className = 'linkItem white uppercase'>Terms and conditions</li>
                            <li className = 'linkItem white uppercase'>Subscribe</li>
                        </ul>
                    </div>  
                    <div className = 'footerContact two'>
                        <div>
                            <h3 className = 'footerHeader white uppercase'>contact us</h3>
                            <ul className = 'contactList'>
                                <li className = 'contactListItem white'><img className = 'footerContactIcon' src = {require('../../../assets/images/phone-alt.png')}/>+27 765 545 6664</li>
                                <li className = 'contactListItem white'><img className = 'footerContactIcon' src = {require('../../../assets/images/envelope.png')}/>contact@vc.co.za</li>
                            </ul>
                            <h3 className = 'footerHeader white uppercase'>Want us to contact you?</h3>
                            <form>
                                <input className = 'footerInput' placeholder = 'Enter Your Email'/>
                            </form>
                        </div>
                        <div className = 'right'>
                            <img className = 'footerLogo' src = {require('../../../assets/images/logoWhite.png')}/>
                            <p className = 'white'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur fugit assumenda aperiam, inventore magnam illum et, ducimus necessitatibus magni porro perferendis dolor</p>
                        </div>
                    </div>
                </div>
                <div className = 'footerBottom'>
                    <div className = 'left'>
                        <p className = 'white'>Copywrite 2020 <span className = 'bold'>Virtual Chambers</span>. All rights reserved </p>
                    </div>
                    <div className = 'right'>
                        <img className = 'footerSocial' src = {require('../../../assets/images/twitterWhite.png')}/>
                        <img className = 'footerSocial' src = {require('../../../assets/images/instagramWhite.png')}/>
                        <img className = 'footerSocial' src = {require('../../../assets/images/facebookWhite.png')}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
