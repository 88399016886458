import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';
import { DefaultBtn } from '../../../components/basicComponents/defaultBtn/defaultBtn';

const Dashboard = () =>{
  return (
    <div>
        <div className = 'top holder'>
            <div className = 'dashboard two'>
                <div className = 'left'>
                    <EnhancedComponents.NoticeBoard/>
                </div>
                <div className = 'right'>
                    <EnhancedComponents.DashboardCommonRoom/>
                </div>
                <div className ='bottom two'>
                    <EnhancedComponents.DashboardLecturesTable/>
                    <EnhancedComponents.IndustryNews/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard