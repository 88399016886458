import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const Juniors = () =>{
  return (
    <div className = 'membersList'>
      <div className = 'top holder'>
        <div className = 'one'>
          <div className = 'flexRow'>
            <h1 className = 'primary bold first'>Our Members</h1>
            <h1 className = 'primary bold active' >Our Juniors</h1>
          </div>
        </div>
      </div>
          <div className = 'membersGrid'>
            <div className = 'holder noPadding'>
              <div className = 'inputGroup'><span className="innericon searchIconBlue"/><input placeholder = 'Search' className = 'defaultSearch' type='text'/></div>
                <h2 className = 'primary'>A</h2>
              </div>
              <div className = 'six grg10'>
                <EnhancedComponents.MembersCard name = 'Sam Kelp' number = '082 333 3333' email = 'example@gmail.com'/>
                <EnhancedComponents.MembersCard name = 'Sam Kelp' number = '082 333 3333' email = 'example@gmail.com'/>
                <EnhancedComponents.MembersCard name = 'Sam Kelp' number = '082 333 3333' email = 'example@gmail.com'/>
                <EnhancedComponents.MembersCard name = 'Sam Kelp' number = '082 333 3333' email = 'example@gmail.com'/>
                <EnhancedComponents.MembersCard name = 'Sam Kelp' number = '082 333 3333' email = 'example@gmail.com'/>
                <EnhancedComponents.MembersCard name = 'Sam Kelp' number = '082 333 3333' email = 'example@gmail.com'/>
              </div>
          </div>
    </div>
  )
}

export default Juniors