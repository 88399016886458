import React from 'react';
// @ts-ignore
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Routes from './routes/routes';
import './App.css';
import './index.scss'
import { AppProvider } from './context';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1884F0' },
    secondary: { main: '#001C38' }
  }

});

const App = () => {
  return (
    <AppProvider>
      <MuiThemeProvider theme={theme}>
        <Routes/>
      </MuiThemeProvider>
    </AppProvider>
  );
};

export default App;
