import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link, NavLink } from 'react-router-dom';


import { useAuthenticationProvider } from '../../../context/authenticationProvider' 

import BasicComponents from '../../basicComponents/index';
import EnhanacedComponents from '../../enhancedComponents/index'

const customStylesOne = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '80%',
    }
  };

  const customStylesTwo = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '65%',
      padding               : '30px',
      height                : '65vh',
    }
  };


export const WebsiteNav = () =>{
    const { auth, authSet } = useAuthenticationProvider()
    const  [dropdown, setDropdown] = useState(false)
    const [modalOne, setModalOne] = useState(false)
    const [modalTwo, setModalTwo] = useState(false)

    const openModal = () => {
        setModalOne(true);
      }
     
      function closeModalOne(){
        setModalOne(false);
        setModalTwo(true);
      }

      function closeModalTwo(){
        setModalTwo(false);
      }

    const closeDropdown = () => {
        setDropdown(false)
        document.body.style.transitionDuration = '1s'
        window.document.getElementById('overlay')!.style.backgroundColor =  'rgba(0,0,0,0)';
        window.document.getElementById('overlay')!.style.pointerEvents =  'none';
    }

    const dropdownSet = () => {
        setDropdown(!dropdown)
        if(dropdown == false){
            document.body.style.transitionDuration = '1s'
            window.document.getElementById('overlay')!.style.backgroundColor =  'rgba(0,0,0,0.5)';
            window.document.getElementById('overlay')!.style.pointerEvents =  'unset';
        }
        else{
            document.body.style.transitionDuration = '1s'
            window.document.getElementById('overlay')!.style.backgroundColor =  'rgba(0,0,0,0)';
            window.document.getElementById('overlay')!.style.pointerEvents =  'none';
        }
    }

    const overlayClose = () => {
        window.document.getElementById('overlay')!.addEventListener('click', function (event) {
            setDropdown(false)
            window.document.getElementById('overlay')!.style.backgroundColor =  'rgba(0,0,0,0)';
            window.document.getElementById('overlay')!.style.pointerEvents =  'none';
        });
    }

    

  return(
      <div>
        <div onClick={overlayClose} className = 'overlay' id = 'overlay'/>
        <div className = 'fixedNav'>
            <div className = 'topNav'>
                <div className = 'socialTop'>
                    <img className = 'socialTopIcon' src = {require('../../../assets/images/twitterBlue.png')}/>
                    <img className = 'socialTopIcon' src = {require('../../../assets/images/facebookBlue.png')}/>
                    <img className = 'socialTopIcon' src = {require('../../../assets/images/instagramBlue.png')}/>
                </div>
            </div>
            <div className = 'mainNav'>
                <div className = 'holderBasic flexRow'>
                    <div className = 'navLogo'>
                        <Link to = {'/home'}><img className = 'logo' src = {require('../../../assets/images/logo.png')}/></Link>
                    </div>

                    <ul className = 'linkList relative'>
                        <NavLink onClick={closeDropdown} activeClassName = 'activeLink' to= {'/home'} className = 'mainLink'>home</NavLink>
                        {auth ?  <a onClick={dropdownSet} className = 'mainLink hoverMenu'>Members Area</a> : null}
                        {dropdown ? 
                            <ul className = {dropdown ? 'dropdownMenu four active' : 'dropdownMenu four' }>

                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/ourmembers'} className = 'subLink primary'>Our Members</NavLink>
                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/ourAttorneys'} className = 'subLink primary'>Our Attorneys</NavLink>
                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/dashboard'} className = 'subLink primary'>Dashboard</NavLink>
                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/skillsVault'} className = 'subLink primary'>Our Skills Vault</NavLink>

                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/ourJuniors'} className = 'subLink primary'>Our Juniors</NavLink>
                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/lectures'} className = 'subLink primary'>Lectures & Training</NavLink>
                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/commonrooms'} className = 'subLink primary'>Common Rooms</NavLink>
                                <NavLink onClick={closeDropdown} activeClassName = 'activeSubLink' to ={'/'} className = 'subLink primary'>Case Blog</NavLink>

                            </ul>
                            : null
                        }
                        <NavLink onClick={closeDropdown} activeClassName = 'activeLink' to= {'/ContactUs'} className = 'mainLink'>contacts</NavLink>
                        <NavLink onClick={closeDropdown} activeClassName = 'activeLink' to= {'/AboutUs'} className = 'mainLink'>about us</NavLink>
                        <NavLink onClick={closeDropdown} activeClassName = 'activeLink' to= {'/WhyJoin'} className = 'mainLink'>why join?</NavLink>
                    </ul>

                    <div className = 'navRight'>
                        <div className = 'inputGroup'><span className="innericon searchicon"/><input placeholder = 'Search' className = 'navSearch' type='text'/></div>
                        {auth ? 
                            <div className = 'inputGroup'><span className = 'signUpButton'></span><button  onClick={()=> {authSet(!auth)}} className = 'signUp'>PROFILE</button></div>
                            :
                            <div className = 'inputGroup'><span className = 'signUpButton'></span><button onClick = {openModal} className = 'signUp'>SIGN IN or JOIN</button>
                                <Modal
                                isOpen={modalOne}
                                onRequestClose={closeModalOne}
                                style={customStylesOne}
                                contentLabel="Example Modal"
                                >
                                    <EnhanacedComponents.SignUpStepOne/>
                                </Modal>
                                <Modal
                                isOpen={modalTwo}
                                onRequestClose={closeModalTwo}
                                style={customStylesTwo}
                                contentLabel="Example Modal"
                                >
                                    <EnhanacedComponents.SignUpStepTwo/>
                                </Modal>
                            </div>
                            }
                        <div className = 'inputGroup'><span className = 'help'/><a className = 'mainLink'>help</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
