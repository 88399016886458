import React from 'react';

import { Link } from 'react-router-dom';

export const DashboardCommonRoom = () =>{
  return(

    <div className = 'dashboardCommonRoom dashboardContainer'>
        <div className = 'one'>

            <div className = 'top'>
                <h1 className = 'primary line'>In The Common Rooms</h1>
                <div className = 'right'>
                    <p className = 'dateYear primary'>currently...</p>
                </div>
            </div>

            <div className = 'commonRoomGrid two'>
                <div className = 'left'>
                    <div>
                        <div className = 'flexRow center'>
                            <p className = 'participants primary bold'>5</p>
                            <img className = 'commonRoomIcon' src = {require('../../../assets/images/speechBubbleBlue.png')}/>
                        </div>
                        <p className = 'primary'>Chats rooms are in use</p>
                    </div>
                </div>
                <div className = 'right'>
                    <div>
                        <p className = 'primary bold'>Chat Room Topics</p>
                        <ul>
                            <li className = 'primary'>Morning Coffee</li>
                            <li className = 'primary'>General Discussion</li>
                            <li className = 'primary'>Property Law</li>
                            <li className = 'primary'>Case Discussion</li>
                            <li className = 'primary'>Jnr's Room</li>
                            <li className = 'primary'>Attorneys Room</li>
                        </ul>
                    </div>
                </div>
                <div className = 'left'>
                    <div>
                        <div className = 'flexRow center'>
                            <p className = 'participants primary bold'>5</p>
                            <img className = 'commonRoomIcon' src = {require('../../../assets/images/userGroupPrimary.png')}/>
                        </div>
                        <p className = 'primary'>Chats rooms are in use</p>
                    </div>
                </div>
                <div className = 'right'>
                    <div>
                        <p className = 'primary bold'>Requested Topics</p>
                        <ul>
                            <li className = 'primary'>Criminal Law</li>
                            <li className = 'primary'>Administrative Law</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}