import React from 'react';

import { Link } from 'react-router-dom';

export const IndustryNews = () =>{
  return(

    <div className = 'industryNews dashboardContainer'>
        <div className = 'top'>
            <h1 className = 'primary line noMarginBottom'>Industry News</h1>
        </div>
        <div className = 'two'>
            <div className = 'left'>
                <h2 className = 'primary uppercase noMarginBottom'>The law has changed</h2>
                <p className = 'subHeading primary medium'> All thanks to one man</p>
                <p className = 'light primary content'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab libero sapiente odit laborum velit nemo ipsa nihil aliquam eligendi similique.</p>
                <p className = 'medium primary link'>Read Full Article</p>
            </div>
        
            <div className = 'right'>
                <div className = 'industryNewsItem'>
                    <p className = 'subHeading primary medium'> All thanks to one man</p>
                    <p className = 'light primary content'>Lorem ipsum dolor sit amet conse adipisicing elit. Ab libero</p>
                    <p className = 'medium primary link'>Read Full Article</p>
                </div>

                <div className = 'industryNewsItem'>
                    <p className = 'subHeading primary medium'> All thanks to one man</p>
                    <p className = 'light primary content'>Lorem ipsum dolor sit amet conse adipisicing elit. Ab libero</p>
                    <p className = 'medium primary link'>Read Full Article</p>
                </div>

                <div className = 'industryNewsItem'>
                    <p className = 'subHeading primary medium'> All thanks to one man</p>
                    <p className = 'light primary content'>Lorem ipsum dolor sit amet conse adipisicing elit. Ab libero</p>
                    <p className = 'medium primary link'>Read Full Article</p>
                </div>

                <div className = 'industryNewsItem'>
                    <p className = 'subHeading primary medium'> All thanks to one man</p>
                    <p className = 'light primary content'>Lorem ipsum dolor sit amet conse adipisicing elit. Ab libero</p>
                    <p className = 'medium primary link'>Read Full Article</p>
                </div>
            </div>
        </div>
    </div>
  )
}