import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import EnhancedComponents from '../components/enhancedComponents/index';

import Homepage from '../containers/website/homepage/homepage';
import AboutUs from '../containers/website/aboutUs/aboutUs';
import WhyJoin from '../containers/website/whyJoin/whyJoin';
import ContactUs from '../containers/website/contactUs/contactUs';
import Dashboard from '../containers/website/dashboard/dashboard';
import Lectures from '../containers/website/lectures/lectures';
import CommonRooms from '../containers/website/commonRooms/commonRooms';
import Attorneys from '../containers/website/attorneys/attorneys';
import Juniors from '../containers/website/juniors/juniors';
import SkillsVault from '../containers/website/skillsVault/skillsVault';
import ProfilePage from '../containers/website/profilePage/profilePage';
import Members from '../containers/website/members/members'


const Routes = () => {
  // eslint-disable-next-line no-unused-vars
  const getRoutes = () => {
    return window.location.pathname !== '/login';
  };

  return (
    <BrowserRouter>
      <EnhancedComponents.WebsiteNav/>
        <Switch>
          <Route exact path={'/home'} component={Homepage}/>
          <Route exact path={'/whyjoin'} component={WhyJoin}/>
          <Route exact path={'/aboutus'} component={AboutUs}/>
          <Route exact path={'/contactus'} component={ContactUs}/>
          <Route exact path={'/dashboard'} component={Dashboard}/>
          <Route exact path={'/lectures'} component={Lectures}/>
          <Route exact path={'/commonrooms'} component={CommonRooms}/>
          <Route exact path={'/ourAttorneys'} component={Attorneys}/>
          <Route exact path={'/ourJuniors'} component={Juniors}/>
          <Route exact path={'/skillsVault'} component={SkillsVault}/>
          <Route exact path={'/profilePage'} component={ProfilePage}/>
          <Route exact path={'/ourmembers'} component={Members}/>
          <Route exact path={'*'} component={Homepage}/>
        </Switch>
      <EnhancedComponents.WebsiteFooter/>
    </BrowserRouter>
  );
};

export default Routes;
