import React from 'react';

import { Link } from 'react-router-dom';

import EnhancedComponents from '../index'

export const DashboardLecturesTable = () =>{
  return(

    <div className = 'dashboardContainer lecturesTable'>
        <div className = 'top'>
                <h1 className = 'primary line noMarginBottom'>Lectures</h1>
                <div className = 'right'>
                    <p className = 'primary'>*All lectures are held on <br/> Saturdays from 9am - 10am</p>
                </div>
            </div>
            <h2 className = 'primary bold'>This Weeks Topics</h2>
        <div className = 'dashboardLecturesTable'>
            <EnhancedComponents.LecturesTable/>
        </div>
    </div>
  )
}